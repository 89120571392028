export function getFirebaseConfig(nodeEnv) {
    if (nodeEnv === 'production') {
        return {
            apiKey: "AIzaSyAAkMuPCUdA-0tjCYaeMVPjGKhhX3cbOQw",
            authDomain: "cbwaterpolo-4c69f.firebaseapp.com",
            databaseURL: "https://cbwaterpolo-4c69f.firebaseio.com",
            projectId: "cbwaterpolo-4c69f",
            storageBucket: "cbwaterpolo-4c69f.appspot.com",
            messagingSenderId: "980497606619",
            appId: "1:980497606619:web:0085ea3c2ac48a850d69fe"
          }
    } else if (nodeEnv === 'preview') {
        return {
            apiKey: "AIzaSyAypN-u3MF2TvtXizraxav_qizDvSu78pM",
            authDomain: "cbwaterpolo-staging.firebaseapp.com",
            databaseURL: "https://cbwaterpolo-staging.firebaseio.com",
            projectId: "cbwaterpolo-staging",
            storageBucket: "cbwaterpolo-staging.appspot.com",
            messagingSenderId: "649577042952",
            appId: "1:649577042952:web:efe7b6bf00358fc3ebadbd"
          }
    } else {
        return {
            apiKey: "AIzaSyArpr8mzf5EvxG0hJqATfZjaNqbiWYSJ1U",
            authDomain: "cbwaterpolo-dev.firebaseapp.com",
            databaseURL: "https://cbwaterpolo-dev.firebaseio.com",
            projectId: "cbwaterpolo-dev",
            storageBucket: "cbwaterpolo-dev.appspot.com",
            messagingSenderId: "89044935746",
            appId: "1:89044935746:web:c4db64e0ef975d768979db"
        }
    }
}