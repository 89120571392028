import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css';

import LandingPage from './components/containers/LandingPage'
import JoinOrganization from './components/containers/JoinOrganization'

const PageContainer = styled.div`
	height: 100vh;
	width: 100vw;
	background-color: #007cff;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CardContainer = styled.div.attrs({ className: 'card shadow-sm p-4' })`
    width:300px;
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Logo = styled.img.attrs({ src: './logo.png' })`
    height:75px;
    width: 75px;
`

const Card = ({ children }) => (
  <CardContainer>
      <LogoContainer>
          <Logo/>
      </LogoContainer>
      {children}
  </CardContainer>
)

function App() {
  return (
    <PageContainer> 
      <Card>
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} /> 
            <Route exact path="/redeem" component={JoinOrganization}/>
          </Switch>
        </Router>
      </Card>
    </PageContainer> 
  )
}

export default App;
