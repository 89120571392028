import React from 'react'
import * as _ from 'lodash'

export function TextInput(
{
    label,
    name,
    onChange,
    value,
    type,
    placeholder,
    disabled,
    className,
    has_error,
    error_message
}) {
    return (
        <div className="form-group">
            {!_.isNil(label) && <label>{label}</label>}
            <input
                onChange={onChange}
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                className={`form-control ${className} ${has_error ? "is-invalid" : ""}`}
            />
            {has_error && <div className="invalid-feedback">{error_message}</div>}
        </div>
    )
}