import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from "react-router-dom";

import { UserProvider, login, onAuthStateChange } from '../../models/User'
import { LoginView } from '../auth/LogInView'
import MessageLabel from '../text/MessageLabel'
import UserJoinOrganization from './UserJoinOrganization'

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default function JoinOrganization() {
    const [user, setUser] = useState({ loggedIn: false });
    const [error, setError] = useState("")

    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUser);
        return () => {
        unsubscribe();
        };
    }, []);
  
    const requestLogin = useCallback((username, password) => {
        login(username, password)
        .catch(error => {
            console.log(error)
            setError(error.message)
        })
    });

    if (!user.loggedIn) {
        return (
            <LoginView onClick={requestLogin} error={error}/>
        )
    } else {
        const token = useQuery().get("token")
        if (token) {
            return (
                <UserProvider value={user}>
                    <UserJoinOrganization token={token} user={user} />               
                </UserProvider>
            )
        } else {
            return (
                <MessageLabel>
                    Missing token, please retry with a new invitation.
                </MessageLabel>
            )
        }
    }
}