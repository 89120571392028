import React from 'react';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database' 
import * as _ from 'lodash'
import { getFirebaseConfig } from '../config/values'

firebase.initializeApp(getFirebaseConfig(process.env.NODE_ENV));

const database = firebase.database()

export class Name {
  constructor(firstName, lastName) {
    this.firstName = firstName
    this.lastName = lastName
  }
}

export class AuthenticatedUser {
  constructor(id, name, database) {
    this.loggedIn = true
    this.id = id
    this.name = name
    this.database = database
  }
}

export function onAuthStateChange(callback) {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const userRef = `users/${user.uid}/public`
        database.ref(userRef).on("value",  
          (snapshot) => {
              const val = snapshot.val()
              const name = _.get(val, 'name', {})
              callback(new AuthenticatedUser(user.uid, name, database))
          },
          (error) => {
            callback({ loggedIn: false })    
          }
        )
      } else {
        callback({ loggedIn: false })
      }
    });
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(username, password)
    .then(() => resolve())
    .catch(error => reject(error))
  })
}

export function logout() {
    firebase.auth().signOut();
}

const defaultUser = { loggedIn: false, email: "" };
export const UserContext = React.createContext(defaultUser);
export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
